import React, { useState, useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/barcode.css";
import SideNav from "./sidenav";
import { useLocation } from "react-router-dom";
import currencyFormate from "currency-formatter";
import { SMSPage } from './child/SMSPage'
import { QRPage } from './child/QRPage'

export const DetailsPage = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const sourceBtn = state.source_btn;
  console.log(sourceBtn)
  const [promoterDetails, setPromoterDetails] = useState(state.promoterDetails);
  const [userDetails, setUserDetails] = useState(state.userDetails);
  const [currentSegment, setCurrentSegment] = useState(state.currentSegment);
  //console.log("details page" + JSON.stringify(state));
  const [loanId, setLoanId] = useState({});
  const isSilZero = state.isSilZero;
  console.log('isSilZero',state)
  const [RBPObject, setRBPObject] = useState(state.RBPObject);
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "PHP",
  });
  const formatterNodecimal = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "PHP",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  //console.log(state.value)
  const [toggle, setToggle] = useState(false);
  function toggleMenu() {
    setToggle(!toggle);
  }
  //console.log("------"+state.productDetails.vasFlag);

  const getItemsList = () => {
    const items = state.productDetails.categories;
    //console.log(items)
    const res = items
      .map((item) => item.categoryName)
      .reduce((acc, categoryName) => {
        return acc + ", " + categoryName;
      });
    //console.log("Data1 :",JSON.stringify(state.productDetails.id));
    return res;
  };

  const backToItemsPage = () => {
    navigate("/Add-items", {
      replace:true
    });
  };
  useEffect(() => {
    setLoanId({ ...loanId, id: state.productDetails.id });
  }, []);

  const setPayHingaFee = () => {
    if (state.productDetails.vasFlag === 0) {
      const vasFeeId = document.getElementById("vasFeeId");
      vasFeeId.style.display = "none";
    }
  };
  setTimeout(setPayHingaFee, 500);
  const innerContent = (
    <Fragment>
      <div className="shoping-details" >
        <div className="row">
          {sourceBtn == "push-qr" ? (
            <QRPage loanId={loanId} />
          ) : (
            <SMSPage loanId={loanId} backToItemsPage={backToItemsPage} />
          )}
          <div className="col-md-8 mb-3">
            <div className="QR_product_info h-100">
              <div className="container">
                <div className="QR_info" >
                  <div className="row" >
                    <div className="col">
                      <label>Items</label>
                    </div>
                    <div className="col">{getItemsList()}</div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <label> Total price</label>
                    </div>
                    <div className="col">
                      <span>
                        {formatter.format(state.productDetails.total)}
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <label>Down payment</label>
                    </div>
                    <div className="col">
                      <span>
                        {formatter.format(
                          state.productDetails.downPaymentAmount
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <label> Loanable amount</label>
                    </div>
                    <div className="col">
                      <span>
                        {formatter.format(
                          state.productDetails.loanableAmount
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <label>Terms</label>
                    </div>
                    <div className="col">
                      {state.productDetails.tenure} months
                    </div>
                  </div>
                  <div id="vasFeeId" className="row">
                    <div className="col">
                      <label> PayHinga fee </label>
                    </div>
                    <div className="col">
                      <span>
                        {formatter.format(
                          state.productDetails.vas.vasFee
                        )}
                      </span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      <label> Monthly installment</label>
                    </div>
                    <div className="col">
                      <span>
                        {formatter.format(
                          state.productDetails.monthlyAmount
                        )}
                      </span>
                    </div>
                  </div>
                  {RBPObject.status === "Y" && (
                    <div className="row">
                      <div className="col">
                        <label> Add-on rate</label>
                      </div>
                      <div className="col">
                        <span>
                          {currentSegment.addOnRate.toFixed(2)}%
                        </span>
                      </div>
                    </div>
                  )}
                  {(RBPObject.status === "Y") ? 
                    <div className="row">
                      <div className="col">
                        <label>Processing fee</label>
                      </div>
                      <div className="col">
                        <span>
                          {formatter.format(
                            isSilZero && state.productDetails.processFee > 0 ? state.productDetails.processFee/state.productDetails.tenure : 0
                          )}
                        </span>
                      </div>
                    </div>
                  : "" }
                  {sourceBtn != "push-qr" ? (
                    <>
                      <div className="row">
                        <div className="col">
                          <label>Name of Promoter</label>
                        </div>
                        <div className="col">
                          <span>
                            {JSON.parse(sessionStorage.getItem("userDetails")).firstName}
                          </span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <label>Mobile Number of Promoter</label>
                        </div>
                        <div className="col">
                          <span>
                          {JSON.parse(sessionStorage.getItem("userDetails")).mobile}
                          </span>
                        </div>
                      </div>
                    </>
                  ) : ""}
                  {/* <div className="row" style={{ display: "none" }}>
                    <div className="col">
                      <label>Employee ID</label>
                    </div>
                    <div className="col">
                      {state.productDetails.promoter.id}
                    </div>
                  </div>
                  <div className="row" style={{ display: "none" }}>
                    <div className="col">
                      <label> Purple key</label>
                    </div>
                    <div className="col">
                      {state.productDetails.promoter.purpleKey}
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
  return (
    <div className="QR-Code-Page d-flex" style={{ backgroundColor: "rgb(250, 250, 252)" }}>
      <div
        className="side-nav-bar"
        style={{ display: toggle ? "block" : "none" }}
      >
        <SideNav position="1" />
      </div>
      <div className="main-body">
        <div className="toggle-btn">
          <button
            className="btn btn-light"
            onClick={() => {
              toggleMenu();
            }}
          >
            <i className="fa fa-align-justify" aria-hidden="true"></i>
          </button>
        </div>
        {sourceBtn == "push-qr" ? (<Fragment>
          <div className="page-title">QR Generator</div>
          <div className="item-details">
            <div className="item-details-desc">
              <div className="QR_title">QR Code</div>
              <div className="sub-title py-2">
                Kailangang ipa-scan sa customer ito
              </div>
            </div>
            {innerContent}
          </div>
        </Fragment>
        ) : (
          <Fragment>
            <div className="page-title">Send SMS</div>
            <div className="item-details">
              {innerContent}
            </div>
          </Fragment>
        )}

        <div className="Next-btn d-flex w-100">
          <div className="next-page-btn  text-end w-100">
            <button className="btn btn-dark" onClick={backToItemsPage}>
              Done <i className="fa fa-arrow-right" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

//export default BarCode;
