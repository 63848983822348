import axios from 'axios';
import forge from 'node-forge';
import secureLocalStorage from "react-secure-storage";
export function validation(form) {
    let errors = {}
    if (isEmpty(form.username)) {
        errors.username = "Please Enter Username";
    }
    if (isEmpty(form.password)) {
        errors.password = "Please Enter Password";
    }

    return errors;

}
const isEmpty = (value) => (
    value === undefined ||
    value === null ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0)
)

export const getAccessToken = () => {
    //apigee access token api call
    let access_token = ''
    const username = process.env.REACT_APP_APIGEE_OAUTH_EMAIL;
    const password = process.env.REACT_APP_APIGEE_OAUTH_PASSWORD;


    return axios.post(process.env.REACT_APP_APIGEE_API_DM + process.env.REACT_APP_APIGEE_OAUTH_API, {}, {
        headers: {
            "Content-Type": 'application/x-www-form-urlencoded',
            "Access-Control-Allow-Origin": "*"
        },
        auth: {
            "username": username,
            "password": password
        }
    })
}

export const getRefreshToken = async (accessToken, secretToken) => {
    return axios.post(process.env.REACT_APP_APIGEE_API_DM + process.env.REACT_APP_API_REFRESH_TOKEN, {}, {
        headers: {
            "Authorization": accessToken,
            "tdb-token": secretToken,
        }
    })
}

export const handleAESEncrypt = (AES, plainText) => {
    const key = forge.util.hexToBytes(AES);
    let iv = forge.random.getBytesSync(16);
    const ivHex = forge.util.bytesToHex(iv);
    iv = forge.util.hexToBytes(ivHex);
    const cipher = forge.cipher.createCipher('AES-CBC', key);
    cipher.start({ iv });
    cipher.update(forge.util.createBuffer(plainText, 'utf8'));
    cipher.finish();

    const encrypted = cipher.output.toHex(); // Use hex for consistency

    return [ivHex, encrypted];
};

export const handleRSAEncrypt = (plainText) => {
    let encrypted = window.handleRSA.encryptMessage(plainText,process.env.REACT_APP_API_PUBLIC_KEY);
    return encrypted;
}

export const saltGeneration = (inputData, accessToken=false) => {
    let saltData = JSON.parse(JSON.stringify(inputData));
    saltData.clientSalt = forge.util.bytesToHex(forge.random.getBytesSync(16));
    saltData = JSON.stringify(saltData);
    let encryptedPass = handleRSAEncrypt(saltData);
    if(accessToken) {
        accessToken = `Bearer ${accessToken}`;
        secureLocalStorage.setItem("TDB-Auth", accessToken);
    }
    return [encryptedPass, saltData, accessToken];
}

export const setLoginSuccess = (promoterDetails, saltData) => {
    sessionStorage.setItem("userDetails", JSON.stringify(promoterDetails));
    secureLocalStorage.setItem("TDB-TOKEN", promoterDetails.secret_token)
    secureLocalStorage.setItem("OFFSETS", {
        userId: promoterDetails.userId,
        clientSalt: JSON.parse(saltData).clientSalt,
        serverSalt: promoterDetails.offset2
    })

    delete promoterDetails['secret_token'];
    delete promoterDetails['offset2'];
    return promoterDetails;
}

export const encryptRequestData = (request) => {
    const saltObj = secureLocalStorage.getItem("OFFSETS");
    const saltKey = `${saltObj.clientSalt}${saltObj.serverSalt}`;
    let [ivHex, encrypted] = handleAESEncrypt(saltKey,JSON.stringify(request));

    const headers = {
        'hash-request': encrypted,
        'hash-iv': ivHex
    };

    return headers;
}