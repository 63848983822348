// components/VolumeGauge.jsx
import React from 'react';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const VolumeGauge = ({ actualValue, targetValue, dailyPerfomance }) => {
  const percentage = Math.min((actualValue / targetValue) * 100, 100); // Limit to 100%
  return (
    <>
      <div class="card border-0">
        <div class="card-header">
          Featured
        </div>
        <div class="card-body">
          <div className="gauge-container">
            <CircularProgressbarWithChildren
              strokeWidth={5}
              backgroundPadding={100}
              value={percentage}
              styles={buildStyles({
                pathColor: dailyPerfomance ? "#FF5C5C" : '#28a745',
                trailColor: '#EAEAEA',
                strokeLinecap: 'butt',
                rotation: 0.65,
              })}
            >
              <div className="gauge-content">
                <span className="gauge-value">
                  <h4 className='fw-semibold chartText'>Sales Achievement</h4>
                  <h6>{actualValue.toLocaleString()}K</h6>
                </span>
              </div>
            </CircularProgressbarWithChildren>

            {/* Range labels below the circular gauge */}
            <div className="range-labels">
              <span>0.0K</span>
              <span>{targetValue.toLocaleString()}K</span>
            </div>

            {/* Interactive message */}
            <div className="interactive-message">
              Your target was <strong>{targetValue.toLocaleString()}K</strong>, 
              but you achieved <strong>{actualValue.toLocaleString()}K</strong>!
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default VolumeGauge;
