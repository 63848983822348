import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const Computation = ({promoterPerfomance, dailyPerfomance, daysInMonth, todayDate}) => {
    const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "PHP",
    });
    const dailyAvg = promoterPerfomance.CUTOFF/daysInMonth;
    const currentAvg = dailyAvg * todayDate;
    const defaultBooking = promoterPerfomance.DEDUCTION;
    return (
        <>
            <div className="card border-0 computations h-100">
                <div className="card-header">Computation</div>
                <div className="card-body border-0 py-4">
                    <table className="table table-sm border mb-0">
                        <tbody>
                            <tr>
                                <td>Total Volume</td>
                                <td><span className={dailyPerfomance ? "bg-danger badge" : "bg-success badge"}>{formatter.format(promoterPerfomance.VOL)}</span></td>
                            </tr>
                            <tr>
                                <td>AFA</td>
                                <td><span className="badge bg-warning">{formatter.format(parseFloat(promoterPerfomance.CURR_AFA).toFixed(2))}</span></td>
                            </tr>
                            <tr>
                                <td>Booking Cutoff</td>
                                <td><span className="badge bg-success">{promoterPerfomance.CUTOFF}</span></td>
                            </tr>
                            <tr>
                                <td>Booked</td>
                                <td><span className={promoterPerfomance.BOOKING >= currentAvg ? "badge bg-success" : "badge bg-danger"}>{promoterPerfomance.BOOKING}</span></td>
                            </tr>
                            <tr>
                                <td>VAS Attached</td>
                                <td><span className={promoterPerfomance.BOOKING >= currentAvg ? "badge bg-success" : "badge bg-danger"}>{promoterPerfomance.VAS_BOOKING}</span></td>
                            </tr>
                            <tr>
                                <td>Default Booking</td>
                                <td><span className={defaultBooking > 0 ? "badge bg-danger": "badge bg-primary"}>{defaultBooking}</span></td>
                            </tr>
                            <tr style={{"background-color": '#f0f0f0'}}>
                                <td><b>Final Bonus</b></td>
                                <td><b><span className="badge bg-info">{formatter.format(promoterPerfomance.FINAL_BONUS_CRED)}</span></b></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default Computation;