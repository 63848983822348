import React from "react";
import { Form, Navigate } from "react-router-dom";
import { isLogin } from "./helper";

const PrivateRoute =({component:Component, ...rest})=>{

    if(isLogin()){
        if(Component.name === "Dashboard") {
            let userData = sessionStorage.getItem("userDetails");
            let userDataJson = JSON.parse(userData);
            if(!userDataJson.showDashboard) {
                return <Navigate to="/Add-items"/>;
            }
        }
        return <Component {...rest}/>;
    }
    return <Navigate to="/"/>;
};
export default PrivateRoute;
