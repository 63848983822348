import secureLocalStorage from "react-secure-storage";
const token = "userToken";

export const login = (msg) => {
    localStorage.setItem(token, msg);
}
export const logoutUser = () => {
    secureLocalStorage.clear();
    localStorage.removeItem(token);
    window.location.replace('/');
}

export const isLogin = () => {
    if (localStorage.getItem(token)) {
        return true;
    }
    return false;
}