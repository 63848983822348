import React , {useEffect,useState} from "react";
import SideNav from "./sidenav";
import "font-awesome/css/font-awesome.min.css";
import CurrencyInput from "react-currency-input-field";
import Loader from "./loader";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "../../src/App.css";
import idPresentImg from "../image/idpresent.svg";
import idPhisicalImg from "../image/signature.svg";
import "../styles/checkboxCustom.css";
import "../styles/generator.css";
import Calculations from './Calculations';
import LoanSummaryDetails from './child/LoanSummaryDetails'
import InstallmentTerms from './child/InstallmentTerms'
import CompareScreen from './child/CompareScreen'
import DownPayment from './child/Modal/DownPayment'


export const Welcome = () => {
const state = {
  promoterDetails: JSON.parse(sessionStorage.getItem("promoterDetails")),
  selectedValue: sessionStorage.getItem("merchantId"),
  userDetails: JSON.parse(sessionStorage.getItem("userDetails")),
};
const logic = Calculations(state);
const enableSMSBtn = state.promoterDetails?.isSMSEnabled && state.promoterDetails.isSMSEnabled == 1;
const [modalContent, setModalContent] = useState(""); // Add this line
const [toggle, setToggle] = useState(false);
function toggleMenu() {
  setToggle(!toggle);
}
useEffect(() => {
  // Fetch your static HTML content here
  fetch("/Payhinga.html")
    .then((response) => {
      //  alert(response); // This alerts the response object
      return response.text(); // Return the text content of the response
    })
    .then((content) => {
      //console.log('content:', content);
      setModalContent(content);
    })
    .catch((error) => console.error("Error fetching HTML content:", error));
}, []);

const DownPayModalObject =  {
                              showDownPay: logic.showDownPay,
                              handleDownPayClose: logic.handleDownPayClose,
                              formatter: logic.formatter,
                              downPayment: logic.downPayment,
                              handledownpayChange: logic.handledownpayChange,
                              handleDecimalNumbers: logic.handleDecimalNumbers,
                              handlePasteDecimalNumbers: logic.handlePasteDecimalNumbers,
                              RBPObject: logic.RBPObject,
                              disableApplynow: logic.disableApplynow,
                              handleApplyNow: logic.handleApplyNow
                            };
  // Comparison Flow
  return (
    <>
      <Modal
        size="sm"
        windowClass="modal-rounded"
        centered={true}
        show={logic.assesmentPopup}
        onHide={logic.assesmentPopupClose}
        animation={false}
      >
        <Modal.Header
          closeButton
          style={{ borderBottom: "none", backgroundColor: "#f7f7f8" }}
        >
          <Modal.Title style={{ color: "black", fontSize: "16px" }}>
            A few house rules
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "#f7f7f8", width: "100%" }}>
          <div>
            <label
              style={{ color: "black", marginBottom: "12px", fontSize: "12px" }}
            >
              By creating a loan offer...
            </label>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                height: "60px",
                backgroundColor: "white",
                marginBottom: 10,
                borderRadius: 8,
                alignItems: "center",
                justifyContent: "center",
                boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  border: 1,
                  flex: 0.5,
                  marginLeft: 10,
                  marginRight: 10,
                }}
              >
                {" "}
                <label>
                  <input
                    className="checkbox-custom"
                    style={{
                      display: "flex",
                      borderRadius: "100%",
                      height: "20px",
                      width: "20px",
                      appearance: "none", // Add this line to remove default styles
                      borderWidth: "1px", // Add this line to set the border width
                      borderStyle: "solid", // Add this line to set the border style
                      borderColor: "#999", // Add this line to set the border color
                      backgroundColor: logic.isCheckedOption1 ? "#785aff" : "#f7f7f8", // Add this line to set the background color based on the checked state
                    }}
                    type="checkbox"
                    checked={logic.isCheckedOption1}
                    onChange={() => logic.setcheckboxValueOption1(!logic.isCheckedOption1)}
                  />
                </label>
              </div>

              <div
                style={{ display: "flex", flexDirection: "column", flex: 6 }}
              >
                <span
                  style={{ color: "black", fontSize: "12px", lineHeight: 1 }}
                >
                  {logic.promotorConcernDetails.idPresent}
                </span>
              </div>
              <div style={{ flex: 1.5, backgroundColor: "#00ffff00" }}>
                <img
                  className="img1 px-3"
                  style={{ height: "60px" }}
                  src={idPhisicalImg}
                  alt=""
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                height: "60px",
                backgroundColor: "white",
                border: 2,
                borderRadius: 8,
                alignItems: "center",
                boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  border: 1,
                  flex: 0.5,
                  marginLeft: 10,
                  marginRight: 10,
                }}
              >
                <label>
                  <input
                    className="checkbox-custom"
                    style={{
                      display: "flex",
                      borderRadius: "100%",
                      height: "20px",
                      width: "20px",
                      appearance: "none", // Add this line to remove default styles
                      borderWidth: "1px", // Add this line to set the border width
                      borderStyle: "solid", // Add this line to set the border style
                      borderColor: "#999", // Add this line to set the border color
                      backgroundColor: logic.isCheckedOption2 ? "#785aff" : "#f7f7f8", // Add this line to set the background color based on the checked state
                    }}
                    type="checkbox"
                    checked={logic.isCheckedOption2}
                    onChange={() => logic.setcheckboxValueOption2(!logic.isCheckedOption2)}
                  />
                </label>
              </div>
              <div
                style={{ display: "flex", flexDirection: "column", flex: 6 }}
              >
                <span
                  style={{ color: "black", fontSize: "12px", lineHeight: 1 }}
                >
                  {logic.promotorConcernDetails.physicalPresent}{" "}
                </span>
              </div>
              <div style={{ flex: 1.5, backgroundColor: "#00ffff00" }}>
                <img
                  className="img1 px-3"
                  style={{ height: "60px" }}
                  src={idPresentImg}
                  alt=""
                />
              </div>
            </div>

            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                id="as0"
                type="radio"
                name="tab"
                value="0"
                onClick={() => {
                  logic.setAssesmentValue("0");
                }}
                style={{
                  width: "25%",
                  margin: "5%",
                  flexDirection: "row",
                  height: 80,
                  borderRadius: 8,
                  backgroundColor: "white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                }}
              >
                <label
                  id="as0_lable"
                  style={{
                    color: "black",
                    fontWeight: "bold",
                    alignSelf: "center",
                  }}
                >
                  0
                </label>
              </div>
              <div
                id="as1"
                type="radio"
                name="tab"
                value="1"
                onClick={() => {
                  logic.setAssesmentValue("1");
                }}
                style={{
                  width: "25%",
                  margin: "5%",
                  height: 80,
                  borderRadius: 8,
                  backgroundColor: "white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                }}
              >
                <label
                  id="as1_lable"
                  style={{ color: "black", fontWeight: "bold" }}
                >
                  1
                </label>
              </div>
              <div
                id="as2"
                type="radio"
                name="tab"
                value="2"
                onClick={() => {
                  logic.setAssesmentValue("2");
                }}
                style={{
                  width: "25%",
                  margin: "5%",
                  height: 80,
                  borderRadius: 8,
                  backgroundColor: "white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                }}
              >
                <label
                  id="as2_lable"
                  style={{ color: "black", fontWeight: "bold" }}
                >
                  2
                </label>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <label
                style={{
                  marginBottom: "10px",
                  fontSize: "12px",
                  textAlign: "center",
                }}
              >
                {logic.promotorConcernDetails.confirmMsg}{" "}
              </label>
            </div>

            <div className="d-grid gap-2">
              <Button
                id="as_proceed"
                className="btn btn-primary p-2"
                style={
                  logic.disableProceed
                    ? {
                        background: "#707070",
                        color: "#e1d5d5",
                        "font-family": "inherit",
                      }
                    : {
                        background: "#785aff",
                        color: "#e1d5d5",
                        "font-family": "inherit",
                      }
                }
                disabled={logic.disableProceed}
                onClick={logic.proceedQRInfo}
              >
                Proceed
              </Button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer
          style={{
            color: "white",
            backgroundColor: "#f7f7f8",
            width: "100%",
            borderTop: "none",
          }}
        ></Modal.Footer>
      </Modal>
      <DownPayment {...DownPayModalObject} />
      <Modal
        windowClass="modal-rounded"
        show={logic.payhingaPopup}
        onHide={logic.payhingaPopupClose}
        animation={false}
        style={{ marginTop: "-60px" }}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body
          style={{
            backgroundColor: "#f7f7f8",
            width: "100%",
            maxHeight: "70vh",
            overflowY: "auto",
          }}
        >
          <div dangerouslySetInnerHTML={{ __html: modalContent }} />
        </Modal.Body>
        <Modal.Footer
          style={{
            color: "white",
            backgroundColor: "#f7f7f8",
            width: "100%",
            borderTop: "none",
          }}
        >
          {/* Add your modal footer content here */}
        </Modal.Footer>
      </Modal>

      {logic.showCompare && <CompareScreen showCompareModal={logic.showCompareModal} handleCompareModalClose={logic.handleCompareModalClose} setShowCompareModal={logic.setShowCompareModal} downPayment={logic.downPayment} totalAmount={logic.totalAmount} allItems={logic.allItems} currentSegmentObj={logic.currentSegmentObj} RBPObject={logic.RBPObject} loanableAmount={logic.loanableAmount} handleApplyNow={logic.handleApplyNow} setSelected={logic.setSelected} getEMIDetails={logic.getEMIDetails} merchantStore={logic.merchantStore} isSilZero={logic.isSilZero} applicationType={logic.applicationType} /> }

      <div className="generator-page d-flex">
        {logic.isLoading == true ? <Loader /> : ""}
        <div
          className="side-nav-bar"
          style={{ display: toggle ? "block" : "none" }}
        >
          <SideNav position="1" />
        </div>
        <div className="main-body" style={{ backgroundColor: "#FAFAFC" }}>
          <div className="toggle-btn">
            <button
              className="btn btn-light"
              onClick={() => {
                toggleMenu();
              }}
            >
              <i className="fa fa-align-justify" aria-hidden="true"></i>
            </button>
          </div>
          <div className="page-title">QR Generator</div>
          <div className="item-details">
            <div className="item-details-desc">
              <div className="title">Add to cart</div>
              <div className="sub-title">
                I-add ang item na gusto ni client bilhin gamit ang loan. You
                can bundle up to 3 items per QR code
              </div>
              <br />
              <div className="items-list">
                <div className="container">
                  <div className="row row-flex">
                    {logic.allItems.length > 0 && (
                      <>
                        {logic.allItems.map((field, index) => (
                          <div key={index} className="col-md-4 col-sm-12">
                            <div className="add-player-div d-flex flex-column border border-light p-2">
                              <h4 className="text-dark py-4">
                                ITEM {index + 1}
                              </h4>
                              <form key={index}>
                                <select
                                  name="categoryName"
                                  placeholder="Enter Category"
                                  className="mb-3 form-select form-control uppercase-input category-name"
                                  value={field.productDescription}
                                  onChange={(e) => {
                                    logic.handleInputChange(e, index);
                                  }}
                                  defaultValue={''}
                                >
                                  <option
                                    disabled={true}
                                    value=""
                                  >
                                    Category
                                  </option>
                                  {logic.merchantStore.map((items, index) => {
                                    return (
                                      <option
                                        key={index}
                                        id={items.productId}
                                        data-interestrate={
                                          items.productDownpayment
                                        }
                                        data-priority={
                                          (items.priority) ? items.priority : '--'
                                        }
                                        value={items.productDescription}
                                      >
                                        {items.productDescription}
                                      </option>
                                    );
                                  })}
                                </select>
                                <input
                                  type="text"
                                  name="brand"
                                  data-id={index}
                                  placeholder="Brand"
                                  maxLength="50"
                                  className="mb-3 form-control uppercase-input"
                                  value={field.brand}
                                  onChange={(e) =>
                                    logic.handleInputChange(e, index)
                                  }
                                />

                                <input
                                  type="text"
                                  name="skuNo"
                                  data-id={index}
                                  placeholder="SKU"
                                  maxLength="50"
                                  className="mb-3 form-control uppercase-input"
                                  value={field.sku}
                                  onChange={(e) =>
                                    logic.handleInputChange(e, index)
                                  }
                                />

                                <CurrencyInput
                                  intlConfig={{ locale: 'en-PH', currency: 'PHP' }}
                                  id="amount"
                                  maxLength={6}
                                  className="mb-3 form-control uppercase-input"
                                  prefix="₱"
                                  name="amount"
                                  placeholder="Price"
                                  onChange={(e) =>
                                    logic.handleInputChange(e, index)
                                  }
                                  onKeyDown={(e) =>
                                    logic.handleDecimalNumbers(e)
                                  }
                                  onInput={(e) =>
                                    logic.handlePasteDecimalNumbers(e)
                                  }
                                />
                              </form>
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                    {logic.allItems.length <= 2 && (
                      <>
                        <div className="col-md-4">
                          <div className="add-player-div d-flex justify-content-center align-items-center border border-light p-2">
                            <div className="Add-btn p-4 text-dark">
                              <p onClick={() => logic.handleAddItems()}>
                                + Add more item
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <LoanSummaryDetails {...logic} />
          
          <InstallmentTerms {...logic} />
          <div className="Next-btn">
              <div className="next-page-btn">
                {enableSMSBtn ? <button
                  className="btn btn-dark"
                  onClick={() => {
                    logic.assesmentPopupShow('push-sms');
                  }}
                  disabled={logic.disableButton || logic.isValidQR}
                >
                  Send SMS
                  <i className="fa fa-arrow-right" aria-hidden="true"></i>
                </button> : ""}
                <button
                  className="btn btn-dark"
                  onClick={() => {
                    logic.assesmentPopupShow('push-qr');
                  }}
                  disabled={logic.disableButton || logic.isValidQR}
                >
                  Generate QR Code{" "}
                  <i className="fa fa-arrow-right" aria-hidden="true"></i>
                </button>
              </div>
            </div>
        </div>
      </div>
      
    </>
  );
};

export default Welcome;
