import React, { useEffect, useState } from "react";
import SideNav from "./sidenav";
import "font-awesome/css/font-awesome.min.css";
import Loader from "./loader";
import "../../src/App.css";
import "../styles/checkboxCustom.css";
import "../styles/generator.css";
import VolumeGauge from './child/powerBi/VolumeGauge';
import Computation from './child/powerBi/Computation';
import axiosInstance from "../js/axiosInstance";
import '../styles/VolumeGauge.css';
import secureLocalStorage from "react-secure-storage";

function getDaysInCurrentMonth() {
  const now = new Date(); // Get the current date
  const year = now.getFullYear(); // Get the current year
  const month = now.getMonth(); // Get the current month (0-indexed)

  return new Date(year, month + 1, 0).getDate(); // Dynamically calculate days
}

export const Dashboard = () => {
  const [toggle, setToggle] = useState(false);
  const [dailyPerfomance, setDailyPerfomance] = useState(0);
  const [targetValue, setTargetValue] = useState(0);
  const [riskPercent, setRiskPercent] = useState(0);
  const currentDate = new Date();
  const daysInMonth = getDaysInCurrentMonth();
  const todayDate = currentDate.getDate();


  function toggleMenu() {
    setToggle(!toggle);
  }

  Number.prototype.kFormatter = function () {
    let num = this;
    return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k' : Math.sign(num) * Math.abs(num)
  }

  const promoterDetail = JSON.parse(sessionStorage.getItem("userDetails"));
  let cachePerfomanceData = secureLocalStorage.getItem("TDB-PROMOTER-PERFOMANCE");
  cachePerfomanceData = cachePerfomanceData !== null ? cachePerfomanceData : {};
  const [promoterPerfomance, setPromoterPerfomance] = useState(cachePerfomanceData);
  const [dataFailed, setDataFailed] = useState(false);
  const [loading, setLoading] = useState(false);
  const setPromoterReport = async () => {
    let promoterData = promoterPerfomance;
    if (Object.keys(promoterPerfomance).length === 0) {
      const apigeeDomain = process.env.REACT_APP_APIGEE_API_DM;
      const getURL = apigeeDomain + process.env.REACT_APP_API_PROMOTER_DASHBOARD + `?userID=${promoterDetail.userId}`;
      setLoading(true);
      await axiosInstance
        .get(getURL)
        .then((response) => {
          if (response.data.status === "00" && response.data.data.length > 0) {
            promoterData = response.data.data[0];
            promoterData.targetValue = response.data.targetValue;
            promoterData.risk = response.data.risk;
            secureLocalStorage.setItem("TDB-PROMOTER-PERFOMANCE", promoterData); // FRONTEND CACHE
            setPromoterPerfomance(promoterData);
          } else {
            setDataFailed(true);
          }
          setLoading(false);
        })
        .catch((error) => {
          setDataFailed(true);
          setLoading(false);
        });
    }

    if (Object.keys(promoterData).length) {
      setTargetValue(promoterData.targetValue);
      setRiskPercent(promoterData.risk);
      let dailyPerform = (promoterData.targetValue / daysInMonth) * todayDate;
      dailyPerform = dailyPerform > promoterData.VOL / 1000;
      setDailyPerfomance(dailyPerform)
    }
  }
  useEffect(() => {
    setPromoterReport();
  }, []);

  return (
    <>
      <div className="generator-page promoter-dashboard d-flex">
        <div
          className="side-nav-bar"
          style={{ display: toggle ? "block" : "none" }}
        >
          <SideNav position="3" />
        </div>
        <div className="main-body" style={{ backgroundColor: "#f0f4ff" }}>
          <div className="toggle-btn">
            <button
              className="btn btn-light"
              onClick={() => {
                toggleMenu();
              }}
            >
              <i className="fa fa-align-justify" aria-hidden="true"></i>
            </button>
          </div>

          <div className="row">
            <header className="header">
              <h1 className="fw-bold">TONIK</h1>
              <h2 className="mb-4 fw-semibold">Current Monthly Perfomance</h2>
              {/* <h3 className="promoter-name">Jondi Lyn Cerrada Catbagan</h3> */}
            </header>
            {Object.keys(promoterPerfomance).length ?
              <>
                <div className="col-md-12">
                  <div className="row stats-cards">
                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 mb-3 stat-card">
                      <div class={promoterPerfomance.BOOKING > promoterPerfomance.CUTOFF ? "card border-0 bg-success" : "card border-0 bg-danger"}>
                        <div class="row g-0">
                          <div class="col-4 align-items-center d-flex justify-content-center">
                            <span class={promoterPerfomance.BOOKING > promoterPerfomance.CUTOFF ? "badge bg-success" : "badge bg-danger"}>
                              <svg class="svg-white" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#fff"> <path d="M0 0h24v24H0V0z" fill="none"></path> <path d="M12 5.9c1.16 0 2.1.94 2.1 2.1s-.94 2.1-2.1 2.1S9.9 9.16 9.9 8s.94-2.1 2.1-2.1m0 9c2.97 0 6.1 1.46 6.1 2.1v1.1H5.9V17c0-.64 3.13-2.1 6.1-2.1M12 4C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 9c-2.67 0-8 1.34-8 4v3h16v-3c0-2.66-5.33-4-8-4z"></path> </svg>
                            </span>
                          </div>
                          <div class="col-8">
                            <div class="card-body p-2">
                              <p class="card-text mb-2">Booked</p>
                              <h5 class="card-title mb-2 fw-bold">{promoterPerfomance.BOOKING}</h5>
                              <p class="card-text"><small class="fw-semibold">vs CutOff: {promoterPerfomance.CUTOFF}</small></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 mb-3 stat-card">
                      <div class={promoterPerfomance.FSTPD30 < riskPercent ? "card border-0 bg-success" : "card border-0 bg-danger"}>
                        <div class="row g-0">
                          <div class="col-4 align-items-center d-flex justify-content-center">
                            <span class={promoterPerfomance.FSTPD30 < riskPercent ? "badge bg-success" : "badge bg-danger"}>
                              <svg class="svg-white" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#fff"> <g> <rect fill="none" height="24" width="24"></rect> <g> <path d="M19,5v14H5V5H19 M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3L19,3z"></path> </g> <path d="M14,17H7v-2h7V17z M17,13H7v-2h10V13z M17,9H7V7h10V9z"></path> </g> </svg>
                            </span>
                          </div>
                          <div class="col-8">
                            <div class="card-body p-2">
                              <p class="card-text mb-2">FSTPD 30%</p>
                              <h5 class="card-title mb-2 fw-bold">{parseFloat(promoterPerfomance.FSTPD30).toFixed(2)}</h5>
                              <p class="card-text"><small class="fw-semibold">{`vs < ${riskPercent}% risk`}</small></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 mb-3 stat-card">
                      <div class="card border-0 bg-purple">
                        <div class="row g-0">
                          <div class="col-4 align-items-center d-flex justify-content-center">
                            <span class="badge bg-purple">
                              <svg xmlns="http://www.w3.org/2000/svg" class="svg-white secondary" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#fff"><path d="M0,0h24v24H0V0z" fill="none"></path><g><path d="M19.5,3.5L18,2l-1.5,1.5L15,2l-1.5,1.5L12,2l-1.5,1.5L9,2L7.5,3.5L6,2v14H3v3c0,1.66,1.34,3,3,3h12c1.66,0,3-1.34,3-3V2 L19.5,3.5z M15,20H6c-0.55,0-1-0.45-1-1v-1h10V20z M19,19c0,0.55-0.45,1-1,1s-1-0.45-1-1v-3H8V5h11V19z"></path><rect height="2" width="6" x="9" y="7"></rect><rect height="2" width="2" x="16" y="7"></rect><rect height="2" width="6" x="9" y="10"></rect><rect height="2" width="2" x="16" y="10"></rect></g></svg>
                            </span>
                          </div>
                          <div class="col-8">
                            <div class="card-body p-2">
                              <p class="card-text mb-2">Avg Financed Amt</p>
                              <h5 class="card-title mb-2 fw-bold text-success">{promoterPerfomance.CURR_AFA.kFormatter()}</h5>
                              <p class="card-text"><small class="fw-semibold">vs PrevMonth: {promoterPerfomance.PREV_AFA.kFormatter()}</small></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="gauge-card">
                    <VolumeGauge actualValue={promoterPerfomance.VOL / 1000} targetValue={targetValue} dailyPerfomance={dailyPerfomance} />
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="row h-100">
                    <div className="col-md-12">
                      <Computation promoterPerfomance={promoterPerfomance} dailyPerfomance={dailyPerfomance} daysInMonth={daysInMonth} todayDate={todayDate} />
                    </div>
                  </div>
                </div>
              </> : ""}
            {loading ? <Loader /> : ""}
            {dataFailed ?

              <div className="row">
                <div className="col-md-12">
                  <div className="card border-0  p-4 text-center">
                    <h4>Data Not Available at the Moment</h4>
                  </div>
                </div>
              </div>

              : ""}
          </div>
        </div>
      </div>
    </>
  );
};
