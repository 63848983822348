import axios from "axios";
import axiosInstance from "../js/axiosInstance";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import Loader from './loader';

export const MerchantPage = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const promoterDetails = JSON.parse(sessionStorage.getItem("userDetails"));

  const [selectedValue, setSelectedValue] = useState('');
  const [merchantName, setMerchantName] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const handleDropdownChange = (event) => {
    const selectedMerchant = event.target.options[event.target.selectedIndex].dataset.merchantname;
    sessionStorage.setItem("merchantName", selectedMerchant);
    sessionStorage.setItem("merchantId", event.target.value);
    setSelectedValue(event.target.value);
    event.target.size = 1; event.target.blur();
  };

  useEffect(() => {
    setIsButtonDisabled(selectedValue === '');
  }, [selectedValue]);

  const handleNextButtonClick = async () => {
    setIsLoading(true);

    try {
      const responseData = await getMerchantInfo();
      sessionStorage.setItem("promoterDetails", JSON.stringify(responseData));

      navigate('/Add-items');
    } catch (error) {
      console.error('Error fetching merchant info:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getMerchantInfo = async () => {
    const options = {
      params: {
        'merchantCode': selectedValue,
        'channelId': 'promoter'
      }
    };

    const URL = `${process.env.REACT_APP_APIGEE_API_DM}${process.env.REACT_APP_PROMOTOR_MERCHANT_API}`;

    try {
      const response = await axiosInstance.get(URL, options);
      const responseData = response.data.data;

      responseData.downPayIntrest = responseData.downPayInterest == null || responseData.downPayInterest <= 0 ? 0.00 : responseData.downPayInterest;
      console.log(responseData);

      return responseData;
    } catch (err) {
      console.log('Promoter error details', err);
      throw err;
    }
  };

  const merchantListUrl = `${process.env.REACT_APP_APIGEE_API_DM}${process.env.REACT_APP_API_PROMOTOR_MERCHANT}?userId=${promoterDetails.userId}`;
  
  useEffect(() => {
    setIsLoading(true);

    axiosInstance.get(merchantListUrl)
      .then(response => {
        console.log("Merchant ID res", response.data.data.merchants);
         if (response && response.data && response.data.data && response.data.data.merchants) {
            setMerchantName(response.data.data.merchants);
		 } else {
              console.log("Invalid merchantListurl response format or missing data");
         }
      })
      .catch(error => {
        console.log("Exception:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <div>
      {isLoading ? <Loader /> : ""}
      <div className="container-fluid">
        <h2>Merchant Store</h2>
        <div className="modal-body mb-3">
          <select className="form-control" value={selectedValue} onChange={handleDropdownChange} >
            <option value="">Select Merchant Name</option>
            {merchantName.map((merchant, index) => (
              <option key={index} value={merchant.merchantCode} data-merchantname={merchant.merchantName}>
                {merchant.merchantName}
              </option>
            ))}
          </select>
        </div>
        <div className="d-flex justify-content-center">
          <button className="btn btn-danger" onClick={handleNextButtonClick} disabled={isButtonDisabled}>Next</button>
        </div>
      </div>
    </div>
  );
};
