import React, { useEffect, useState,useRef } from 'react';
import '../styles/login.css';
import { useNavigate } from "react-router-dom"
import { saltGeneration, setLoginSuccess } from "./Validation";
import { login } from '../RouteGuards/helper';
import Loader from './loader';
import validate from './ValidateChangePassword';
import PasswordChecklist from "react-password-checklist"
import axiosInstance from "../js/axiosInstance";


export const PassWordChange = () => {

  const ref = useRef(null)
//======Test Login  valid User============//
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [newPasswordShown, setnewPasswordShown] = useState(false);
  const [confirmPasswordShown, setconfirmPasswordShown] = useState(false);
  const [getInvalidUser,setInvalidUser]=useState();
  const [confirmPassword, setconfirmPassword] = useState("");
  const [newPassword, setnewPassword] = useState("");
  const [enable, setEnable]=useState(true);
  
  const [invalidPassword,setInvalidPassword]=useState();
  const [validateForm, setValidateForm] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  const [errors, setError] = useState({

  })

  function handleChange(e) {
    setValidateForm({ ...validateForm, [e.target.name]: e.target.value });
    //console.log(validateForm)
    if(e.target.name=="confirmPassword"){
        setconfirmPassword(e.target.value)
       // setError(validate(validateForm))
    }   
  }

  const validateChangeForm=()=>{
    setError(validate(validateForm))
    if(Object.keys(errors).length === 0 && (validateForm.confirmPassword !== "" && validateForm.newPassword !== "")){
      setEnable(false)
    }else{
      setEnable(true)
    }
  }

  const inputData = {
    userId:  sessionStorage.getItem("userId"),
    password: validateForm.confirmPassword
  };

  function onSubmitForm(e) {
    e.preventDefault();
    ChangePassword();
  }
  //alert(errors.newPassword)
  const togglenewPassword = () => {
    setnewPasswordShown(!newPasswordShown);
  };
  const toggleconfirmPassword = () => {
    setconfirmPasswordShown(!confirmPasswordShown);
  };
  const ChangePassword=()=>{
    setIsLoading(false)
     const URL = process.env.REACT_APP_APIGEE_API_DM + process.env.REACT_APP_API_USER;
     console.log("Env:", URL);
    //  getAccessToken().then(res => {
      //   console.log('auth API token-', JSON.stringify(res.data.access_token));
      let [encryptedPass,saltData] = saltGeneration(inputData);
      inputData.password = encryptedPass;
      axiosInstance.patch(URL, inputData)
      .then((res) => {
        setInvalidUser('');
        login('success');
        if(res.data.status==="x05"){
          setIsLoading(false)
          navigate("/Change-Password");
        }
        else if(res.data.status==="x03" || res.data.status==="x04" || res.data.status==="x07"){
          setIsLoading(false)
          setInvalidUser(res.data.message);
        }else if(res.data.status==="00"){
          const promoterDetails = setLoginSuccess(res.data.data,saltData);
          navigate('/merchantpage');
          setIsLoading(false)
        }
        else{
          setIsLoading(false)
          setInvalidUser("We are unable to process your login request , please contact admin");
        }
        
        
      })
      .catch((err) => {
        setIsLoading(false)
        setInvalidUser("We are unable to process your login request , please contact admin");
      })
         
      //  })
       
      //  .catch(error =>
      //    console.error('getMerchantDetails REACT_APP_APIGEE_API_DM ', error));
 }

  useEffect(() => {
    localStorage.removeItem("userToken")
    if(Object.keys(errors).length === 0 && (validateForm.confirmPassword !== "" && validateForm.newPassword !== "")){
      setEnable(false)
    }else{
      setEnable(true)
    }
  if (ref.current) return;
  ref.current = true;
  }, [errors])
   return (
    <div className="password-change-page mb-5">
      {isLoading ==true? <Loader /> : ""}
       <div className="container">
      <div className="login-page">
      <div className="login-label pb-5">
          <div className="login-title"><p className='mb-0'>Change Password!</p><p className='text-color mb-0'>to Purple App</p></div>
          <span className="sub-title text-secondary">Confirm gamit ang access id at password</span>
        </div>
        <form onSubmit={onSubmitForm}>
          <p className='text-center text-danger invalid-user'>{invalidPassword}</p>
          <div className="form-group">
            <label className="form-label">New Password</label>
            <input type={newPasswordShown ? "text" : "password"} className="form-control mb-2"  name="newPassword" placeholder='Enter New Password' value={validateForm.newPassword}
             onChange={handleChange} onKeyUp={validateChangeForm}
            /><div className='toggle-eye'><i className={newPasswordShown ? "fa fa-eye" : "fa fa-eye-slash"} 
            id="togglePassword" onClick={togglenewPassword} ></i></div>
            {errors.newPassword && <p style={{ color: "red", fontSize: "13px", fontWeight: "bold" }}>{errors.newPassword}</p>}

          </div>
          <div className="form-group">
            <label className="form-label">Confirm Password</label>
            <input type={confirmPasswordShown ? "text" : "password"} className="form-control mb-2" name="confirmPassword" placeholder='Enter Confirm Password' value={validateForm.confirmPassword}
         
         onChange={handleChange} onKeyUp={validateChangeForm}
            /><div className='toggle-eye'><i className={confirmPasswordShown ? "fa fa-eye" : "fa fa-eye-slash"} 
            id="togglePassword" onClick={toggleconfirmPassword}></i></div>
            {errors.confirmPassword && <p style={{ color: "red", fontSize: "13px", fontWeight: "bold" }}>{errors.confirmPassword}</p>}
          </div>
          <div className="form-group">
            <button type="submit" className="btn btn-dark w-100 form-control font-weight-bold" disabled={enable}>Confirm</button>
          </div>
        </form>
        <PasswordChecklist
				rules={["minLength","specialChar","number","capital"]}
				minLength={8}
              
				value={confirmPassword}
                notEmpty={confirmPassword}
                invalidColor="yellow"
                iconComponents={{ValidIcon:<i className="fa fa-check mt-2 px-2" style={{ color: "#06e406", fontSize: "13px", fontWeight: "bold"}} aria-hidden="true"></i>, InvalidIcon: <i className="fa fa-circle  mt-2 px-2" style={{ color: "red", fontSize: "13px", fontWeight: "bold" }}  aria-hidden="true"></i>}}
				messages={{
					minLength: "Minimum Length 8 Characters.",     
					specialChar: "Atleast One Special Charecter",
					number: "Atleast One Numeric Charecter",
					capital: "Atleast One Upper Case Alphabet",
				}}
			/>
        </div>

       
    </div>
    </div>
  );
}

export default PassWordChange;